import {FeatureApp, createFeatureAppIntegrator} from '@smart/feature-app/react';
// New
// import '@smart/react-components/index.css';
// Old
import '@smart/web-components/dist/foundation.css';
import React from 'react';
import {getClientConfig} from './utils/get-client-config';

const clientConfig = getClientConfig();

createFeatureAppIntegrator({
  App: ({endpointDirectory: {envName}}) => (
    <React.Fragment>
      <FeatureApp
        name="pdf"
        envName={envName}
        config={{usePDFViewer: clientConfig.usePDFViewer}}
      />
    </React.Fragment>
  ),
  id: `pdf-feature-app-integrator`,
  clientConfig,
});
