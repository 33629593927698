import type {EndpointDirectoryV3, EnvNameV3} from '@smart/endpoint-directory';
import type {ClientConfig} from '@smart/feature-app/react';
import {getLanguageTag} from '@smart/locale-service';

interface PdfClientConfig extends ClientConfig {
  usePDFViewer: string | null;
}

export function getClientConfig(): PdfClientConfig {
  const url = new URL(window.location.href);
  const language = url.searchParams.get(`language`) || `de`;
  const preview = url.searchParams.get(`preview`) === `true`;
  const envName = (process.env.TARGET as EnvNameV3) || (`dev` as EnvNameV3);
  const marketId = url.searchParams.get(`marketId`) || `de`;
  const usePDFViewer = url.searchParams.get(`usePDFViewer`);

  const endpointDirectory: EndpointDirectoryV3 = {
    //TODO: Not needed but verify if it's needed
    rotatingVerifySecret: undefined,
    envName,
    preview,
  };

  return {
    endpointDirectory,
    locale: {
      marketId,
      languageTag: getLanguageTag(language, marketId),
      language,
    },
    usePDFViewer,
  };
}
